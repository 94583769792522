import { DeliverableDataMatterport } from '~common/model/Deliverable';

export default function DeliverableRenderMatterport({ data }: { data: DeliverableDataMatterport }): JSX.Element {
  return (
    <iframe
      src={data.url}
      allowFullScreen
      allow="xr-spatial-tracking"
      className="w-full h-full overflow-hidden bg-black"
    />
  );
}
