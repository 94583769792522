import { DeliverableDataVideo, DeliverableFileVideo } from '~common/model/Deliverable';
import VideoPlayer from '~/components/VideoPlayer';

export default function DeliverableRenderVideo({
  data,
  video,
}: {
  data: DeliverableDataVideo;
  video: DeliverableFileVideo;
}): JSX.Element {
  return (
    <div className="flex flex-col w-full h-full">
      <div className="text-sm text-center">{data.caption ?? 'No Caption Provided'}</div>
      <div className="flex-1 relative">
        <div className="absolute inset-0 w-full h-full">
          <VideoPlayer m3u8={video.m3u8} mp4={video.mp4} />
        </div>
      </div>
    </div>
  );
}
