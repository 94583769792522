import { DeliverableDataFloorplan } from '~common/model/Deliverable';
import { FileImageSizes } from '~/.server/drizzle/entity/FileEntity';

export default function DeliverableRenderFloorplan({
  data,
  image,
}: {
  data: DeliverableDataFloorplan;
  image: FileImageSizes;
}): JSX.Element {
  return (
    <div className="flex flex-col w-full h-full">
      <div className="text-sm text-center">
        {' '}
        {data.floors.length === 1 ? `Floor ${data.floors[0]}` : `Floors ${data.floors.join(' & ')}`}
      </div>
      <div className="flex-1 relative">
        <img
          src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
          srcSet={`${image.large_1x}, ${image.large_2x} 2x`}
          className="absolute inset-0 w-full h-full object-contain max-h-[800px] select-none"
        />
      </div>
    </div>
  );
}
